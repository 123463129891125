import { TranslateProps } from "../components/Translation/Translate";

export interface DocumentsRecord {
  id: string;
  showDate: string | TranslateProps;
  showType: string;
  documentType: TaxFormType;
  relatedWith: string;
  taxYear?: number;
  imported?: boolean;
  tokenTicker?: string;
  name?: string;
  fileKey?: string;
}

export enum TaxFormType {
  W9 = "W-9",
  W8BEN = "W-8BEN",
  W8BENE = "W-8BENE",
  "1042S" = "1042-S",
  "1099B" = "1099-B",
  "1099DIV" = "1099-DIV",
  "1099INT" = "1099-INT",
  "1099K" = "1099-K",
  "1099MISC" = "1099-MISC",
  "OTHER" = "OTHER",
}

export enum TaxIdType {
  SSN = "SSN",
  FOREIGN = "FOREIGN",
}

export enum InvestorType {
  INDIVIDUAL = "individual",
  ENTITY = "entity",
}

export type W9Document = {
  formType: TaxFormType;
  formInvestorName: string;
  businessName?: string;
  address1: string;
  address2?: string;
  addressAdditionalInfo?: string;
  postalCode: string;
  country: string;
  region: string;
  city: string;
  formClassification: string;
  taxPayerIdType: string;
  taxPayerIdNumber: string | null;
  exemptPayeeCodes?: number | number[] | null;
  exemptFATCAReportingCodes?: string | string[] | null;
  taxCountry: string;
};

export type W8BENDocument = {
  formType: TaxFormType;
  formInvestorName: string;
  address1: string;
  address2?: string;
  addressAdditionalInfo?: string;
  postalCode: string;
  country: string;
  region: string;
  city: string;
  taxPayerIdType?: TaxIdType;
  taxPayerIdNumber?: string | null;
  countryOfCitizenship: string;
  taxCountry?: string;
  mailingAddress1?: string;
  mailingAddress2?: string;
  mailingAddressAdditionalInfo?: string;
  mailingPostalCode?: string;
  mailingCountry?: string;
  mailingRegion?: string;
  mailingCity?: string;
  birthDay: Date | string;
  exemptPayeeCodes?: number | number[] | null;
  exemptFATCAReportingCodes?: string | string[] | null;
};

export type W8BENEDocument = {
  formType: TaxFormType;
  contentType: string;
  fileKey: string;
};
