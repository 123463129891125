import { styled } from "@mui/system";
import { WhmStack } from "@securitize/reactjs-whm";

export const TwoColsInputsContainer = styled(WhmStack)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
});

export const ThreeColsInputsContainer = styled(WhmStack)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
});
