import Cookies from 'js-cookie';
import { UserInfo } from "../types/UserInfo";
import { AuthResponse, getUserSession, login } from '../data/rest/auth';
import config from "../data/config";

const AUTH_TOKEN_COOKIE_KEY = 'auth-token';
const getAuthToken = (): string | undefined => {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  return Cookies.get(AUTH_TOKEN_COOKIE_KEY);
};
const setAuthToken = (token: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  Cookies.set(AUTH_TOKEN_COOKIE_KEY, token, {
    expires: 7,
    path: '/',
  });
};

const handleAuthentication: () => Promise<UserInfo> = async () => {
  const { user, token } = await getSessionOrLogin();
  setAuthToken(token);

  return user;
};

const getSessionOrLogin: () => Promise<AuthResponse> = async () => {
  try {
    return await getUserSession();
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return await login(config.LOGIN.USERNAME, config.LOGIN.PASSWORD, '', config.LOGIN.AUTOMATION_CAPTCHA_KEY);
  }
};

export { handleAuthentication, getAuthToken };
