import React from "react";
import {
  WhmCard,
  WhmTypography,
  WhmCardContent,
  WhmTag,
  WhmBox,
} from "@securitize/reactjs-whm";
import { GridMenu } from "./GridMenu";
import { DocumentsRecord } from "../../../types/documents.interface";
import Translate, {
  TranslateProps,
} from "../../../components/Translation/Translate";

export const CardComponent = ({
  row,
  loadServerRows,
}: {
  row: DocumentsRecord;
  loadServerRows: () => void;
}) => {
  const date = (value: string | TranslateProps) => {
    if (typeof value === "string") {
      return value;
    } else {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Translate
          translationKey={value.translationKey}
          replacements={value.replacements}
        />
      );
    }
  };

  return (
    <WhmCard sx={{ marginBottom: "6px" }}>
      <WhmCardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "16px !important",
        }}
      >
        <WhmBox>
          <WhmTypography variant="body2" sx={{ paddingBottom: "8px" }}>
            {row.showType}
          </WhmTypography>
          <WhmTag colorVariant={"default"} label={row.relatedWith} />
        </WhmBox>
        <WhmBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <GridMenu row={row} loadServerRows={loadServerRows} isMobile={true} />{" "}
          <WhmTypography
            variant="caption"
            color="text.secondary"
            sx={{ marginBottom: 1 }}
          >
            {date(row.showDate)}
          </WhmTypography>
        </WhmBox>
      </WhmCardContent>
    </WhmCard>
  );
};
