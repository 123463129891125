import React from "react";

export const Mailbox = () => (
  <svg
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="62" cy="62" r="62" fill="#F9F9F9" />
    <path
      d="M74.1787 76.3926V88.2021V123.262L62 125.845L62.0001 84.5116V76.3926H74.1787Z"
      fill="url(#paint0_linear_889_11334)"
    />
    <path
      d="M99.6037 54.3815L99.1988 76.4592C99.1976 76.5239 99.1837 76.5878 99.1579 76.6471C99.132 76.7064 99.0947 76.7601 99.0481 76.805C99.0015 76.8499 98.9465 76.8852 98.8862 76.9088C98.8259 76.9325 98.7616 76.944 98.6969 76.9428L69.7692 76.4128L40.8414 75.8828L41.5104 39.3722L85.4436 40.1773C93.4063 40.3228 99.7447 46.681 99.6037 54.3815Z"
      fill="url(#paint1_linear_889_11334)"
    />
    <path
      d="M56.3228 54.2499L55.9214 76.1563L25.5528 75.5999L25.9541 53.6936C26.1019 45.628 33.0202 39.2139 41.4061 39.3675C49.792 39.5211 56.4706 46.1842 56.3228 54.2499Z"
      fill="url(#paint2_linear_889_11334)"
    />
    <path
      d="M69.3025 52.0457C69.2615 54.2836 67.3924 56.0689 65.1337 56.0273C62.8751 55.9858 61.0722 54.1335 61.1134 51.8956C61.1274 51.182 61.3302 50.4849 61.701 49.8752C62.0719 49.2654 62.5976 48.7647 63.2247 48.4241L63.7427 20.1553L67.8373 20.2303L67.3194 48.499C67.9337 48.8624 68.4408 49.382 68.7892 50.0049C69.1375 50.6279 69.3146 51.332 69.3025 52.0457Z"
      fill="#ADECED"
    />
    <path
      d="M55.4112 54.8039L55.0201 76.1472L26.4118 75.623L26.8031 54.2796C26.9471 46.4214 33.4682 40.168 41.3681 40.3128C49.268 40.4575 55.5552 46.9456 55.4112 54.8039Z"
      fill="url(#paint3_linear_889_11334)"
    />
    <path
      d="M65.427 53.8168C66.5424 53.6298 67.295 52.5741 67.108 51.4587C66.9211 50.3434 65.8653 49.5908 64.75 49.7777C63.6346 49.9647 62.882 51.0204 63.069 52.1357C63.2559 53.2511 64.3116 54.0037 65.427 53.8168Z"
      fill="white"
    />
    <path
      d="M63.6762 19.8411L77.0647 20.0865C77.1223 20.0875 77.1772 20.1114 77.2172 20.153C77.2572 20.1945 77.2791 20.2502 77.2781 20.3078L77.1578 26.872C77.1576 26.8834 77.1529 26.8942 77.1447 26.9021C77.1365 26.91 77.1255 26.9143 77.1141 26.9141L63.5512 26.6656C63.5398 26.6654 63.529 26.6606 63.5211 26.6524C63.5132 26.6442 63.5088 26.6333 63.509 26.6219L63.6325 19.8832C63.6327 19.8719 63.6374 19.861 63.6456 19.8531C63.6538 19.8452 63.6648 19.8409 63.6762 19.8411Z"
      fill="#7FD7DA"
    />
    <path
      d="M55.9525 76.1646C55.776 76.9918 54.0448 101.044 35.3391 100.701C14.8846 100.667 25.4751 75.6061 25.4751 75.6061L55.9525 76.1646Z"
      fill="#E1E1E1"
    />
    <path
      d="M74.1782 76.393L74.1782 84.5121L62.0097 78.2307L62.045 76.2753L74.1782 76.393Z"
      fill="url(#paint4_linear_889_11334)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_889_11334"
        x1="63.6956"
        y1="76.7616"
        x2="63.9312"
        y2="161.812"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C8C8C8" />
        <stop offset="1" stop-color="#5D6166" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_889_11334"
        x1="65.3522"
        y1="35.376"
        x2="73.0417"
        y2="81.2515"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E3E7ED" />
        <stop offset="1" stop-color="#818487" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_889_11334"
        x1="36.8579"
        y1="39.8559"
        x2="43.2971"
        y2="78.2723"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.205" stop-color="#B6B6B6" />
        <stop offset="1" stop-color="#676464" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_889_11334"
        x1="36.9432"
        y1="40.8047"
        x2="53.8804"
        y2="74.1787"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21" stop-color="#D7D7D7" />
        <stop offset="1" stop-color="#666363" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_889_11334"
        x1="67.6588"
        y1="75.3343"
        x2="69.2351"
        y2="84.7383"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A5A8AD" />
        <stop offset="1" stop-color="#8B8E91" />
      </linearGradient>
    </defs>
  </svg>
);
