import axios from 'axios';
import { getAuthToken } from '../helpers/authHelper';

export const createApiAxios = (url: string, version = '/v1') => {
  const baseURL = `${url}${version}`;
  const apiAxios = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  apiAxios.interceptors.request.use((request) => {
    const bearerToken = getAuthToken();

    if (!request.headers) {
      // @ts-ignore
      request.headers = {};
    }

    if (bearerToken) {
      request.headers.Authorization = bearerToken;
    }

    return request;
  });
  return apiAxios;
};
