import { createContext } from "react";
import { MixPanelType } from "../types/mixpanel.types";

export interface MixPanelContextType {
  MixPanel: MixPanelType;
}

export const MixPanelContext = createContext({} as MixPanelContextType);

type Props = {
  MixPanel: MixPanelType;

  children: React.ReactNode;
};

const MixPanelProvider: React.FC<Props> = ({ MixPanel, children }) => {
  return (
    <MixPanelContext.Provider value={{ MixPanel }}>
      {children}
    </MixPanelContext.Provider>
  );
};

export default MixPanelProvider;
