import {
  WhmStack,
  WhmTypography,
  WhmTextField,
  WhmFormControl,
  WhmCheckbox,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { TwoColsInputsContainer } from "../InputContainers";
import { translationKeys } from "../../../../contexts/I18nContext";
import useTranslations from "../../../../hooks/useTranslations";

function CertificationInputs() {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    formInvestorName?: string;
    businessName?: string;
    nameConfirmation: string;
    formDate: string;
    agree: boolean;
  }>();

  return (
    <WhmStack direction="column" spacing={4}>
      <WhmStack direction="row" spacing={6} alignItems="start">
        <WhmFormControl
          margin="normal"
          error={formik.touched.agree && Boolean(formik.errors.agree)}
        >
          <WhmCheckbox
            id="agree"
            name="agree"
            checked={formik.values.agree}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WhmFormControl>
        <WhmTypography>
          {t(translationKeys.FORM_SECTION_CERTIFICATION_TEXT)}
        </WhmTypography>
      </WhmStack>
      <TwoColsInputsContainer direction="row" spacing={2}>
        <WhmTextField
          required
          id="nameConfirmation"
          name="nameConfirmation"
          label={t(translationKeys.FORM_SECTION_CERTIFICATION_SIGNATURE, {
            name:
              formik.values.formInvestorName ||
              formik.values.businessName ||
              "",
          })}
          value={formik.values.nameConfirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.nameConfirmation &&
            Boolean(formik.errors.nameConfirmation)
          }
          helperText={
            formik.touched.nameConfirmation && formik.errors.nameConfirmation
          }
        />
        <WhmTextField
          id="date-picker"
          name="formDate"
          type="date"
          label={t(translationKeys.FORM_SECTION_CERTIFICATION_DATE)}
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.formDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.formDate && Boolean(formik.errors.formDate)}
          helperText={formik.touched.formDate && formik.errors.formDate}
        />
      </TwoColsInputsContainer>
    </WhmStack>
  );
}

export default CertificationInputs;
