import * as yup from "yup";

export const regexName =
// eslint-disable-next-line
/^[a-zA-Z-áéíóúñZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-/\\. ]+$/i;
export const regexNameWithNumbers =
// eslint-disable-next-line
  /^[a-zA-Z0-9áéíóúñZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-/\\. ]+$/i;

export const addressSchema = ({
  address1,
  address2,
  locality,
  postalCode,
}: {
  address1: string;
  address2: string;
  locality: string;
  postalCode: string;
}) => {
  return {
    [address1]: yup
      .string()
      .required("Address is required")
      .matches(regexNameWithNumbers, "Please enter valid Address")
      .max(50, "Address is too long (max 50 characters)"),
    [address2]: yup
      .string()
      .matches(regexNameWithNumbers, "Please enter valid Address")
      .max(70, "Is too long (max 70 characters)")
      .notRequired(),
    [locality]: yup
      .string()
      .required("Locality is required")
      .matches(regexNameWithNumbers, "Please enter valid City")
      .max(40, "Locality is too long (max 40 characters)"),
    [postalCode]: yup
      .string()
      .max(20, "Postal Code is too long")
      .matches(regexNameWithNumbers, "Please enter valid Postal Code")
      .required("Is required (max 20 characters)"),
  };
};

export const fullNameSchema = ({
  name,
  businessName,
}: {
  name: string;
  businessName: string;
}) => ({
  [name]: yup
    .string()
    .required("Name is a required field")
    .matches(regexName, "Please enter valid name")
    .max(70, "Name is too long (max 70 characters)"),
  [businessName]: yup
    .string()
    .notRequired()
    .min(1, "Business name is too short")
    .matches(regexName, "Please enter valid Business name")
    .max(70, "Business name is too long (max 70 characters)"),
});

export const nameSchema = ({ name }: { name: string }) => ({
  [name]: yup
    .string()
    .required("Name is a required field")
    .matches(regexName, "Please enter valid name")
    .max(62, "Name is too long (max 62 characters)"),
});

export const birthDaySchema = ({ birthDay }: { birthDay: string }) => ({
  [birthDay]: yup.string().required("Is required"),
});

export const RegionSchema = ({ region }: { region: string }) => ({
  [region]: yup
    .string()
    .required("Is required")
    .matches(regexName, "Please enter a valid value")
    .max(22, "Region is too long (max 22 characters)"),
});

export const requiredSchema = ({ required }: { required: string }) => ({
  [required]: yup.string().required("Is required"),
});

export const requiredCitizenSchema = ({ required }: { required: string }) => ({
  [required]: yup
    .string()
    .required("Is required")
    .notOneOf(["US"], "Must not be United States"),
});

export const nameConfirmationSchema = ({
  nameConfirmation,
  agree,
}: {
  nameConfirmation: string;
  agree: string;
}) => ({
  [nameConfirmation]: yup
    .string()
    .test("match-name", "Name does not match", function (value) {
      // eslint-disable-next-line
      const formInvestorName = this.resolve(
        yup.ref("formInvestorName"),
      ) as string;
      return value?.toLowerCase() === formInvestorName?.toLowerCase();
    })
    .required("is a required field"),
  [agree]: yup
    .boolean()
    .oneOf([true], "Is a required field")
    .required("Is a required field"),
});

export const claimOfTaxSchema = ({ taxCountry }: { taxCountry: string }) => ({
  [taxCountry]: yup.string(),
});

export const taxPayerSchema = ({
  classification,
  identifier,
  exemptPayeeCodes,
  exemptFATCAReportingCodes,
  taxPayerIdNumber,
}: {
  classification: string;
  identifier: string;
  exemptPayeeCodes: string;
  exemptFATCAReportingCodes: string;
  taxPayerIdNumber: string;
}) => {
  return {
    [classification]: yup.string().required("Is required"),
    [identifier]: yup.string().required("Is required"),
    [taxPayerIdNumber]: yup.string().notRequired(),
    [exemptPayeeCodes]: yup.number().notRequired(),
    [exemptFATCAReportingCodes]: yup.string().notRequired(),
  };
};

export const w8TaxPayerSchema = ({
  USTaxPayerId,
  foreignTaxPayerId,
}: {
  USTaxPayerId: string;
  foreignTaxPayerId: string;
}) => {
  return {
    [USTaxPayerId]: yup
      .string()
      .matches(regexNameWithNumbers, "Please enter valid Tax Number")
      .max(23, "max 23 characters"),
    [foreignTaxPayerId]: yup
      .string()
      .matches(regexNameWithNumbers, "Please enter valid Tax Number")
      .max(23, "max 23 characters"),
  };
};
