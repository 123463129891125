import { TaxFormType } from "../types/documents.interface";

export const is1099Document = (documentType?: any): boolean => {
  return [
    TaxFormType["1042S"],
    TaxFormType["1099B"],
    TaxFormType["1099DIV"],
    TaxFormType["1099INT"],
    TaxFormType["1099MISC"],
    TaxFormType["1099K"],
  ].includes(documentType as TaxFormType);
};

export const is1099BDocument = (documentType?: any): boolean => {
  return [TaxFormType["1099B"]].includes(documentType as TaxFormType);
};

export const isOtherDocument = (documentType?: any): boolean => {
  return [TaxFormType.OTHER].includes(documentType as TaxFormType);
};

export const isTaxDocument = (documentType?: any): boolean => {
  return [TaxFormType.W9, TaxFormType.W8BEN, TaxFormType.W8BENE].includes(
    documentType as TaxFormType,
  );
};
