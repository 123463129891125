import superagent from "superagent";

export const uploadFileToS3 = (
  presignedPostData: {
    url: string;
    fields: Record<string, string>;
    fileKey: string;
  },
  file: File,
) => {
  return new Promise<boolean>((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    formData.append("content-type", file.type);
    formData.append("file", file);

    superagent
      .post(presignedPostData.url)
      .send(formData)
      .then((response) => {
        response.status === 204 ? resolve(true) : reject(response.body);
      })
      .catch((err: Error) => {
        reject(err.message);
      });
  });
};
