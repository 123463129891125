import { ISR, SHARED } from "@securitize/domain";
import { useEffect, useState } from "react";
import { getInvestorInformation } from "../data/rest/investorInfo";
import {
  InvestorInfo,
  InvestorInfoTax,
} from "../types/investorInformation.interface";
import useUserInfo from "./useUserInfo";
import { KycStatus } from "../types/UserInfo";

type Props = {
  taxesFilter?: "US" | "nonUS";
};

function useInvestorInfo({ taxesFilter }: Props = {}) {
  const user = useUserInfo();
  const [investorInfo, setInvestorInfo] = useState<InvestorInfo | undefined>();

  useEffect(() => {
    if (user && investorInfo === undefined) {
      void getInvestorInformation().then((data) => {
        setInvestorInfo(data ?? null);
      });
    }
  }, [user, investorInfo]);

  const isEntity = user?.investorType === ISR.InvestorTypes.ENTITY;
  const verificationStatus = user.verificationStatus;

  if (
    [KycStatus.NOT_VERIFIED, KycStatus.REJECTED].includes(verificationStatus) ||
    !investorInfo
  ) {
    const initialInvestorInfo = {
      fullName: "",
      birthday: {
        date: "",
        city: "",
        country: "",
        state: "",
      },
      address: {
        street: "",
        houseNumber: "",
        entrance: "",
        city: "",
        countryCode: "",
        state: "",
        zip: "",
      },
      tax: [],
    };
    return {
      isUSPerson: false,
      isNonUSPerson: false,
      isEntity,
      investorInfo: initialInvestorInfo,
      userInfo: user,
    };
  } else {
    const filteredInvestorInfo = { ...investorInfo };
    const isUSPerson =
      investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          tax.taxCountryCode && SHARED.isUsTerritory(tax.taxCountryCode),
      ).length > 0;
    const isNonUSPerson =
      investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          !tax.taxCountryCode || !SHARED.isUsTerritory(tax.taxCountryCode),
      ).length > 0;

    if (taxesFilter === "US") {
      filteredInvestorInfo.tax = investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          tax.taxCountryCode && SHARED.isUsTerritory(tax.taxCountryCode),
      );
    } else if (taxesFilter === "nonUS") {
      filteredInvestorInfo.tax = investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          tax.taxCountryCode && !SHARED.isUsTerritory(tax.taxCountryCode),
      );
    }
    return {
      isUSPerson,
      isNonUSPerson,
      isEntity,
      investorInfo: filteredInvestorInfo,
      userInfo: user,
    };
  }
}

export default useInvestorInfo;
