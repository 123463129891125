import {
  WhmCheckbox,
  WhmFormControl,
  WhmStack,
  WhmTextField,
  WhmTypography,
} from "@securitize/reactjs-whm";
import CountrySelectInput from "./CountrySelectInput";
import StateSelectInput from "./StateSelectInput";
import { useFormikContext } from "formik";
import { ThreeColsInputsContainer } from "../InputContainers";
import { useEffect, useState } from "react";
import AddressInputs from "./AddressInputs";
import { translationKeys } from "../../../../contexts/I18nContext";
import useTranslations from "../../../../hooks/useTranslations";

type Props = {
  disabled?: boolean;
};

function MailingAddressInputs({ disabled }: Props) {
  const [useInvestorAddress, setUseInvestorAddress] = useState(false);
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    country: string;
    region: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingPostalCode: string;
    mailingCountry: string;
    mailingRegion: string;
  }>();

  useEffect(() => {
    if (useInvestorAddress) {
      formik.setValues({
        ...formik.values,
        mailingAddress1: formik.values.address1,
        mailingAddress2: formik.values.address2,
        mailingCity: formik.values.city,
        mailingPostalCode: formik.values.postalCode,
        mailingCountry: formik.values.country,
        mailingRegion: formik.values.region,
      });
    } else {
      formik.setValues({
        ...formik.values,
        mailingAddress1: "",
        mailingAddress2: "",
        mailingCity: "",
        mailingPostalCode: "",
        mailingCountry: "",
        mailingRegion: "",
      });
    }
  }, [useInvestorAddress]);

  const disableInput = disabled;

  return (
    <>
      <WhmStack direction="row" spacing={4} alignItems="center">
        <WhmFormControl margin="normal">
          <WhmCheckbox
            id="use-personal-address"
            name="use-personal-address"
            checked={useInvestorAddress}
            onChange={() => setUseInvestorAddress((prev) => !prev)}
          />
        </WhmFormControl>
        <WhmTypography>
          {t(translationKeys.FORM_SECTION_MAILING_ADDRESS_CHECKBOX)}
        </WhmTypography>
      </WhmStack>
      {useInvestorAddress ? (
        <AddressInputs disabled />
      ) : (
        <ThreeColsInputsContainer
          gap={2}
          sx={{
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <WhmTextField
            required
            disabled={disableInput}
            id="mailingAddress1"
            name="mailingAddress1"
            label={t(translationKeys.FORM_SECTION_ADDRESS_STREET)}
            value={formik.values.mailingAddress1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mailingAddress1 &&
              Boolean(formik.errors.mailingAddress1)
            }
            helperText={
              formik.touched.mailingAddress1 && formik.errors.mailingAddress1
            }
          />
          <WhmTextField
            disabled={disableInput}
            id="mailingAddress2"
            name="mailingAddress2"
            label={t(translationKeys.FORM_SECTION_ADDRESS_ADDITIONAL)}
            value={formik.values.mailingAddress2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mailingAddress2 &&
              Boolean(formik.errors.mailingAddress2)
            }
            helperText={
              formik.touched.mailingAddress2 && formik.errors.mailingAddress2
            }
          />
          <WhmTextField
            required
            disabled={disableInput}
            id="mailingCity"
            name="mailingCity"
            focused
            label={t(translationKeys.FORM_SECTION_ADDRESS_CITY)}
            value={formik.values.mailingCity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mailingCity && Boolean(formik.errors.mailingCity)
            }
            helperText={formik.touched.mailingCity && formik.errors.mailingCity}
          />
          <WhmTextField
            required
            disabled={disableInput}
            id="mailingPostalCode"
            name="mailingPostalCode"
            label={t(translationKeys.FORM_SECTION_ADDRESS_ZIP)}
            value={formik.values.mailingPostalCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mailingPostalCode &&
              Boolean(formik.errors.mailingPostalCode)
            }
            helperText={
              formik.touched.mailingPostalCode &&
              formik.errors.mailingPostalCode
            }
          />
          <CountrySelectInput
            disabled={disableInput}
            inputName="mailingCountry"
          />
          <StateSelectInput
            disabled={disableInput}
            inputName="mailingRegion"
            isUSCountry={formik.values.mailingCountry === "US"}
          />
        </ThreeColsInputsContainer>
      )}
    </>
  );
}

export default MailingAddressInputs;
