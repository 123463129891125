import { RouterProvider } from "react-router-dom";
import { Router } from "@remix-run/router";
import { WhmSnackbarProvider } from "@securitize/reactjs-whm";
import MFEContainer from "./containers/MFEContainer";
import { UserInfo } from "./types/UserInfo";
import UserContext from "./contexts/UserContext";
import { MixPanelType } from "./types/mixpanel.types";

export interface Props {
  language: string;
  userInfo: UserInfo;
  router: Router;
  MixPanel: MixPanelType;
}

function App({ language, userInfo, router, MixPanel }: Props) {
  return (
    <MFEContainer language={language} MixPanel={MixPanel}>
      <UserContext userInfo={userInfo}>
        <WhmSnackbarProvider>
          <RouterProvider router={router} />
        </WhmSnackbarProvider>
      </UserContext>
    </MFEContainer>
  );
}

export default App;
