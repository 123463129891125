import { DocumentsRecord } from "../types/documents.interface";
import { is1099BDocument, isOtherDocument } from "./taxForms.utils";
import {
  downloadFormTax,
  getDownloadAggregateTaxFormLink,
} from "../data/rest/documents";

/**
 * Executes the download by creating a temporary anchor element.
 * @param url - The URL to download.
 */
export const executeDownload = (url: string) => {
  if (url) {
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    document.body.appendChild(link);
    const fileName = url.split("/").pop()!;

    if (/iPhone|iPad/.test(navigator.userAgent)) {
      // For iOS devices, trigger the download on click
      link.addEventListener("click", () => {
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      });
      link.click();
    } else {
      // For other platforms, open the file in a new tab
      link.target = "_blank";
      link.download = fileName;
      link.click();
    }
    document.body.removeChild(link);
  }
};

/**
 * Retrieves the download link based on the document type and executes the download.
 * @param item - The document record.
 */
export const getDownloadLink = async (item: DocumentsRecord) => {
  if (isOtherDocument(item) && item.fileKey) {
    executeDownload(item.fileKey);
  } else if (is1099BDocument(item.documentType) && item.taxYear) {
    const { url } = await getDownloadAggregateTaxFormLink(
      item.documentType,
      item.taxYear,
    );
    executeDownload(url);
  } else {
    const { url } = await downloadFormTax(item.id);
    executeDownload(url);
  }
};
