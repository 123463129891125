import { WhmTextField } from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { ThreeColsInputsContainer } from "../InputContainers";
import CountrySelectInput from "./CountrySelectInput";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";

type Props = {
  variant?: "name" | "full";
  disabled?: boolean;
};

function PersonalInformationInputs({ variant = "name", disabled }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    formInvestorName: string;
    birthDay: string;
  }>();

  return (
    <ThreeColsInputsContainer gap={2}>
      <WhmTextField
        required
        disabled={disabled}
        id="formInvestorName"
        name="formInvestorName"
        label={t(translationKeys.FORM_SECTION_PERSONAL_INVESTOR_NAME)}
        value={formik.values.formInvestorName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.formInvestorName &&
          Boolean(formik.errors.formInvestorName)
        }
        helperText={
          formik.touched.formInvestorName && formik.errors.formInvestorName
        }
        sx={{ width: "100%", gridColumn: variant === "name" ? "1 / 4" : 1 }}
      />
      {variant === "full" && (
        <>
          <CountrySelectInput
            disabled={disabled}
            inputName="countryOfCitizenship"
            inputLabel={t(translationKeys.FORM_SECTION_PERSONAL_CITIZENSHIP)}
          />
          <WhmTextField
            disabled={disabled}
            id="date-picker"
            name="birthDay"
            label={t(translationKeys.FORM_SECTION_PERSONAL_BIRTHDATE)}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.birthDay}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.birthDay && Boolean(formik.errors.birthDay)}
            helperText={formik.touched.birthDay && formik.errors.birthDay}
          />
        </>
      )}
    </ThreeColsInputsContainer>
  );
}

export default PersonalInformationInputs;
