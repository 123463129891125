import { TA } from "@securitize/nestjs-clients";
import taxCenterGw from "../taxCenterGw";
import { DocumentsRecord, TaxFormType } from "../../types/documents.interface";
import { UserInfo } from "../../types/UserInfo";
import { translationKeys } from "../../contexts/I18nContext";
import {
  is1099Document,
  isOtherDocument,
  isTaxDocument,
} from "../../common/taxForms.utils";
import moment from "moment/moment";
import { TranslateProps } from "../../components/Translation/Translate";

const relatedWith = (record: TA.TaxFormsApi.TaxFormDto, userInfo: UserInfo) => {
  switch (true) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    case isTaxDocument(record.formType):
      return userInfo.fullName;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    case is1099Document(record.formType):
      return translationKeys.DOCUMENT_RELATED_WITH_1099;
    case isOtherDocument(record.formType):
      return record.tokenTicker || "";
    default:
      return "";
  }
};

const getDate = (
  record: TA.TaxFormsApi.TaxFormDto,
): TranslateProps | string => {
  if (is1099Document(record.formType) && record.taxYear) {
    return {
      translationKey: translationKeys.TAX_FORMS_DATE_TAX_YEAR,
      replacements: {
        taxYear: record.taxYear,
      },
    };
  } else {
    return moment(record.submissionDate || record.createdAt).format(
      "DD MMM YYYY",
    );
  }
};

const getType = (record: TA.TaxFormsApi.TaxFormDto): string => {
  if (isOtherDocument(record.formType)) {
    return record.name || "";
  }
  return record.formType.toString();
};

export const getDocuments = async (
  userInfo: UserInfo,
): Promise<DocumentsRecord[]> => {
  const { data } = await taxCenterGw.get<{ data: TA.TaxFormsApi.TaxFormDto[] }>(
    "/tax-center",
    {},
  );

  return data.data.map((record: TA.TaxFormsApi.TaxFormDto) => {
    return {
      id: record.id,
      showDate: getDate(record),
      showType: getType(record),
      documentType: record.formType as TaxFormType,
      relatedWith: relatedWith(record, userInfo),
      taxYear: record.taxYear,
      imported: record.imported,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      name: record.name || "",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      tokenTicker: record.tokenTicker || "",
      fileKey: record.fileKey,
    };
  });
};

export const deleteImportedTaxForms = async (): Promise<void> => {
  await taxCenterGw.delete("/tax-center/imported");
};

export const deleteTaxForm = async (id: string): Promise<void> => {
  await taxCenterGw.delete(`/tax-center/${id}`);
};

export const getDownloadAggregateTaxFormLink = async (
  taxFormType: TaxFormType,
  taxYear: number,
): Promise<TA.TaxFormsApi.TaxFormDownloadDto> => {
  const response: { data: TA.TaxFormsApi.TaxFormDownloadDto } =
    await taxCenterGw.get(`/tax-center/${taxFormType}/${taxYear}`);
  return response.data;
};

export const downloadFormTax = async (
  id: string,
): Promise<TA.TaxFormsApi.TaxFormDownloadDto> => {
  const response: { data: TA.TaxFormsApi.TaxFormDownloadDto } =
    await taxCenterGw.get(`/tax-center/${id}`);
  return response.data;
};
