import { BoxOwnProps, Theme } from "@mui/system";
import { WhmBox, WhmTypography } from "@securitize/reactjs-whm";
import React from "react";

type Props = {
  title: string;
  description?: string;
  children: React.ReactNode;
  boxSx?: BoxOwnProps<Theme>["sx"];
};

function FormBlock({ title, description, children, boxSx = {} }: Props) {
  return (
    <WhmBox
      sx={{
        margin: "5px",
        padding: "1.5rem",
        width: "100%",
        background: "white",
        borderRadius: "5px",
        ...(boxSx as object),
      }}
    >
      <WhmTypography
        variant="h5"
        sx={{
          fontWeight: 500,
        }}
      >
        {title}
      </WhmTypography>
      {Boolean(description) && (
        <WhmTypography
          variant="body1"
          sx={(theme) => ({
            marginBottom: "1rem",
            color: theme.palette.text.secondary,
          })}
        >
          {description}
        </WhmTypography>
      )}
      {children}
    </WhmBox>
  );
}

export default FormBlock;
