const translationKeys = {
  HOME_HEADER_TITLE: "home.header.title",
  TAX_FORM_HEADER_TITLE: "tax-form.header.title",
  DOCUMENT_RELATED_WITH_1099: "document.related-with.1099",
  TAX_FORMS_CERTIFICATION_NAME_FORM: "tax-forms.certification.name.form",
  TAX_FORMS_CERTIFICATION_DATE_TAX_YEAR:
    "tax-forms.certification.date.tax-year",
  TAX_FORMS_CERTIFICATION_RELATED_TAX_DOCUMENT:
    "tax-forms.certification.related.tax-document",
  TAX_FORMS_CERTIFICATION_ASSET_MULTIPLE:
    "tax-forms.certification.asset.multiple",
  FORM_SELECTOR_TITLE: "tax-center.form-selector.title",
  FORM_SELECTOR_SUBTITLE: "tax-center.form-selector.subtitle",
  FORM_SELECTOR_W8BEN_DESCRIPTION: "tax-center.form-selector.w8ben.description",
  FORM_SELECTOR_W9_DESCRIPTION: "tax-center.form-selector.w9.description",
  FORM: "form",
  TAX_FORMS_DATE_TAX_YEAR: "tax-forms.date.tax-year",
  TAX_FORMS_RELATED_TAX_DOCUMENT: "tax-forms.related.tax-document",
  TAX_FORMS_ASSET_MULTIPLE: "tax-forms.asset.multiple",
  MENU_ITEM_DELETE: "menu.item.delete",
  MENU_ITEM_DOWNLOAD: "menu.item.download",
  MENU_ITEM_POSTMAIL: "menu.item.postmail",
  // Generic / Feedback
  WORDS_SUBMIT: "tax-center.words.submit",
  WORDS_CANCEL: "tax-center.words.cancel",
  FORM_FEEDBACK_ERROR: "tax-form.feedback.error",
  FORM_FEEDBACK_SUCCESS_DOCUMENT: "tax-form.feedback.success-document",
  // grid
  GRID_HEADER_DATE: "grid.header.date",
  GRID_HEADER_DOCUMENT_TYPE: "grid.header.document-type",
  GRID_HEADER_RELATED_WITH: "grid.header.related-with",
  GRID_PAGINATOR_LABEL_ROWS_PER_PAGE: "grid.paginator.label.rows-per-page",
  GRID_PAGINATOR_LABEL_DISPLAYED_ROWS: "grid.paginator.label.displayed-rows",
  GRID_PAGINATOR_LABEL_DISPLAYED_ROWS_MORE: "grid.paginator.label.displayed-rows-more",
  // Modals
  TAX_CENTER_ALERT_TITLE: "tax-center.alert.title",
  TAX_CENTER_ALERT_MESSAGE: "tax-center.alert.message",
  TAX_CENTER_ALERT_ACTION: "tax-center.alert.action",
  DIALOG_DELETE_TITLE: "dialog.delete.title",
  DIALOG_POSTMAIL_TITLE: "dialog.postmail.title",
  DIALOG_POSTMAIL_BODY_1: "dialog.postmail.body.1",
  DIALOG_POSTMAIL_BODY_2: "dialog.postmail.body.2",
  DIALOG_POSTMAIL_SNACKBAR: "dialog.postmail.snackbar", // Email copied to clipboard
  DIALOG_POSTMAIL_ACTION: "dialog.postmail.action",
  DIALOG_DELETE_BODY_1: "dialog.delete.body.1",
  DIALOG_DELETE_BODY_2: "dialog.delete.body.2",
  DIALOG_ACTION_CANCEL: "dialog.action.cancel",
  DIALOG_ACTION_DELETE: "dialog.action.delete",
  FORM_MODAL_SUBMIT_CONFIRMATION_TITLE:
    "tax-center.form.modal.confirmation.title",
  FORM_MODAL_SUBMIT_CONFIRMATION_SUBTITLE:
    "tax-center.form.modal.confirmation.subtitle",
  FORM_MODAL_SUBMIT_CONFIRMATION_DESCRIPTION:
    "tax-center.form.modal.confirmation.description",
  // Form sections
  FORM_HEADER_TITLE: "tax-form.form.header.title",
  FORM_HEADER_TITLE_DOCUMENT: "tax-form.form.header.title-document",
  FORM_HEADER_SUBTITLE: "tax-form.form.header.subtitle",
  FORM_HEADER_SUBTITLE_DOCUMENT: "tax-form.form.header.subtitle-document",
  FORM_HEADER_SUBTITLE_DOCUMENT_LINK:
    "tax-form.form.header.subtitle-document.link",
  FORM_SECTION_PERSONAL_TITLE: "tax-form.form.section.personal.title",
  FORM_SECTION_PERSONAL_SUBTITLE: "tax-form.form.section.personal.subtitle",
  FORM_W9_SECTION_ADDRESS_TITLE: "tax-form.form-w9.section.address.title",
  FORM_W9_SECTION_ADDRESS_SUBTITLE: "tax-form.form-w9.section.address.subtitle",
  FORM_SECTION_TAX_INFORMATION_TITLE:
    "tax-form.form.section.tax-information.title",
  FORM_SECTION_TAX_INFORMATION_SUBTITLE:
    "tax-form.form.section.tax-information.subtitle",
  FORM_SECTION_EXEMPTIONS_TITLE: "tax-form.form.section.exemptions.title",
  FORM_SECTION_EXEMPTIONS_SUBTITLE: "tax-form.form.section.exemptions.subtitle",
  FORM_SECTION_CERTIFICATION_TITLE: "tax-form.form.section.certification.title",
  FORM_SECTION_CERTIFICATION_SUBTITLE:
    "tax-form.form.section.certification.subtitle",
  FORM_W8BEN_SECTION_PERMANENT_ADDRESS_TITLE:
    "tax-form.form-w8ben.section.permanent-address.title",
  FORM_W8BEN_SECTION_PERMANENT_ADDRESS_SUBTITLE:
    "tax-form.form-w8ben.section.permanent-address.subtitle",
  FORM_W8BEN_SECTION_MAILING_ADDRESS_TITLE:
    "tax-form.form-w8ben.section.mailing-address.title",
  FORM_W8BEN_SECTION_MAILING_ADDRESS_SUBTITLE:
    "tax-form.form-w8ben.section.mailing-address.subtitle",
  // Form inputs
  FORM_SECTION_PERSONAL_INVESTOR_NAME:
    "tax-center.form.section.personal.investor-name.label",
  FORM_SECTION_PERSONAL_CITIZENSHIP:
    "tax-center.form.section.personal.citizenship.label",
  FORM_SECTION_PERSONAL_BIRTHDATE:
    "tax-center.form.section.personal.birthdate.label",
  FORM_SECTION_ADDRESS_STREET: "tax-center.form.section.address.street.label",
  FORM_SECTION_ADDRESS_ADDITIONAL:
    "tax-center.form.section.address.additional.label",
  FORM_SECTION_ADDRESS_CITY: "tax-center.form.section.address.city.label",
  FORM_SECTION_ADDRESS_STATE: "tax-center.form.section.address.state.label",
  FORM_SECTION_ADDRESS_ZIP: "tax-center.form.section.address.zip.label",
  FORM_SECTION_ADDRESS_COUNTRY: "tax-center.form.section.address.country.label",
  FORM_SECTION_MAILING_ADDRESS_CHECKBOX:
    "tax-center.form.section.mailing-address.checkbox.label",
  FORM_SECTION_TAX_INFORMATION_CLASSIFICATION:
    "tax-center.form.section.tax-information.classification.label",
  FORM_SECTION_TAX_INFORMATION_CLASSIFICATION_ENTITY:
    "tax-center.form.section.tax-information.classification.option.entity",
  FORM_SECTION_TAX_INFORMATION_CLASSIFICATION_INDIVIDUAL:
    "tax-center.form.section.tax-information.classification.option.individual",
  FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER:
    "tax-center.form.section.tax-information.tax-identifier.label",
  FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER_SSN:
    "tax-center.form.section.tax-information.tax-identifier.option.ssn",
  FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER_FOREIGN:
    "tax-center.form.section.tax-information.tax-identifier.option.foreign",
  FORM_SECTION_TAX_INFORMATION_TAX_ID_CALLOUT:
    "tax-center.form.section.tax-information.tax-id.callout",
  FORM_W8BEN_SECTION_TAX_INFORMATION_US_TAX_ID:
    "tax-center.form-w8ben.section.tax-information.us-tax-id.label",
  FORM_W8BEN_SECTION_TAX_INFORMATION_FOREIGN_TAX_ID:
    "tax-center.form-w8ben.section.tax-information.foreign-tax-id.label",
  FORM_W8BEN_SECTION_TAX_INFORMATION_HINT:
    "tax-center.form-w8ben.section.tax-information.tax-type-hint",
  FORM_SECTION_EXEMPTIONS_PAYEE:
    "tax-center.form.section.exemptions.payee.label",
  FORM_SECTION_EXEMPTIONS_PAYEE_CTA:
    "tax-center.form.section.exemptions.payee.cta",
  FORM_SECTION_EXEMPTIONS_FATCA:
    "tax-center.form.section.exemptions.fatca.label",
  FORM_SECTION_EXEMPTIONS_FATCA_CTA:
    "tax-center.form.section.exemptions.fatca.cta",
  FORM_MODAL_EXEMPTIONS_PAYEE_TITLE: "tax-center.form.modal.payee-codes.title",
  FORM_MODAL_EXEMPTIONS_FATCA_TITLE: "tax-center.form.modal.fatca-codes.title",
  FORM_SECTION_EXEMPTIONS_PAYEE_CODE:
    "tax-center.form.section.exemptions.payee.code-", // Add numeric index at the end (1 / 13)
  FORM_SECTION_EXEMPTIONS_FATCA_CODE:
    "tax-center.form.section.exemptions.fatca.code-", // Add letter index at the end (A / M)
  FORM_SECTION_CERTIFICATION_TEXT: "tax-center.form.section.certification.text",
  FORM_SECTION_CERTIFICATION_SIGNATURE:
    "tax-center.form.section.certification.signature-name.label",
  FORM_SECTION_CERTIFICATION_DATE:
    "tax-center.form.section.certification.date.label",
  FORM_W8BENE_TITLE: "tax-center.form-w8bene.title",
  FORM_W8BENE_TEXT: "tax-center.form-w8bene.text",
  FORM_W8BENE_SECTION_DROPZONE_TITLE:
    "tax-center.form-w8bene.section.dropzone.title",
  FORM_W8BENE_SECTION_DROPZONE_DESCRIPTION:
    "tax-center.form-w8bene.section.dropzone.description",
  FORM_W8BENE_SECTION_DROPZONE_BUTTON_UPLOAD:
    "tax-center.form-w8bene.section.dropzone.buttons.upload",
  FORM_W8BENE_SECTION_DROPZONE_BUTTON_DELETE:
    "tax-center.form-w8bene.section.dropzone.buttons.delete",
  FORM_W8BENE_SECTION_DROPZONE_BUTTON_DOWNLOAD:
    "tax-center.form-w8bene.section.dropzone.buttons.download",
  // Form buttons
  FORM_BUTTONS_SUBMIT: "tax-form.form.buttons.submit",
} as const;

export default translationKeys;

export type TranslationKey =
  (typeof translationKeys)[keyof typeof translationKeys];
