import { useState } from "react";
import { useNavigate } from "react-router";
import { useWhmSnackbar, WhmDropzone } from "@securitize/reactjs-whm";
import { UserInfo } from "../../../types/UserInfo";
import { InvestorInfo } from "../../../types/investorInformation.interface";
import FormBlock from "./FormBlock";
import {
  WhmButton,
  WhmLink,
  WhmStack,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { ISRLinkResolver } from "./FormTypeSelector";
import { W8BENEFormType } from "./FormType";
import FormConfirmationModal from "./FormConfirmationModal";
import {
  getW8BENEPresignedUrls,
  getW8BENETaxFormExample,
  postW8BENETaxForm,
} from "../../../data/rest/taxForms";
import { uploadFileToS3 } from "../../../helpers/s3.helper";
import { translationKeys } from "../../../contexts/I18nContext";
import useTranslations from "../../../hooks/useTranslations";
import useMixPanel, { TrackingType } from "../../../hooks/useMixPanel";
import { TaxFormType } from "../../../types/documents.interface";

type Props = {
  userInfo: UserInfo;
  investorInfo: InvestorInfo;
};

function W8BENEForm({ userInfo: _, investorInfo: __ }: Props) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState<W8BENEFormType | undefined>();
  const snackbar = useWhmSnackbar();
  const track = useMixPanel();
  const { getTranslation: t } = useTranslations();
  const navigate = useNavigate();

  const onSubmit = async (values: W8BENEFormType) => {
    track(TrackingType.TA_PAYOUTS_W8BEN_E_UPLOAD);
    await postW8BENETaxForm(values);
    snackbar.show(
      t(translationKeys.FORM_FEEDBACK_SUCCESS_DOCUMENT, {
        document: TaxFormType.W8BENE,
      }),
      {
        severity: "success",
      },
    );
    navigate("/tax-center");
  };

  const onDownloadExample = async () => {
    track(TrackingType.TA_PAYOUTS_W8BEN_E_DOWNLOAD);
    await getW8BENETaxFormExample().then(({ url }) => {
      if (url) {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = url.split("/").pop() ?? "";
        link.href = url;
        link.click();
      }
    });
  };

  return (
    <>
      <FormBlock title={t(translationKeys.FORM_W8BENE_TITLE)} description="">
        <WhmStack spacing={2}>
          <WhmTypography
            variant="body2"
            color="textSecondary"
            sx={{ marginBottom: "1rem !important" }}
          >
            {t(translationKeys.FORM_W8BENE_TEXT)}{" "}
            <WhmLink
              href={ISRLinkResolver[TaxFormType.W8BENE]}
              underline="none"
              variant="h6"
              fontWeight="bold"
              rel="noopener noreferrer"
            >
              {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT_LINK)}
            </WhmLink>
          </WhmTypography>
          <WhmDropzone
            title={t(translationKeys.FORM_W8BENE_SECTION_DROPZONE_TITLE)}
            description={t(
              translationKeys.FORM_W8BENE_SECTION_DROPZONE_DESCRIPTION,
            )}
            uploadButtonText={t(
              translationKeys.FORM_W8BENE_SECTION_DROPZONE_BUTTON_UPLOAD,
            )}
            deleteButtonText={t(
              translationKeys.FORM_W8BENE_SECTION_DROPZONE_BUTTON_DELETE,
            )}
            extraActionButtonText={t(
              translationKeys.FORM_W8BENE_SECTION_DROPZONE_BUTTON_DOWNLOAD,
            )}
            onExtraActionClick={onDownloadExample}
            onUpload={async (file: File) => {
              try {
                const presignedPostData = await getW8BENEPresignedUrls(
                  file.type,
                );
                await uploadFileToS3(presignedPostData, file);
                setFormData({
                  contentType: file.type,
                  fileKey: presignedPostData.fields.Key,
                });
              } catch (err) {
                snackbar.show(t(translationKeys.FORM_FEEDBACK_ERROR), {
                  severity: "error",
                });
              }
            }}
            onDelete={async () => {}}
          />
        </WhmStack>
      </FormBlock>
      <WhmButton
        variant="contained"
        color="primary"
        sx={{ width: "fit-content", marginTop: "1rem" }}
        disabled={!Boolean(formData)}
        onClick={() => setShowConfirmationModal(true)}
      >
        {t(translationKeys.FORM_BUTTONS_SUBMIT)}
      </WhmButton>
      {Boolean(formData) && showConfirmationModal && (
        <FormConfirmationModal
          open={showConfirmationModal}
          onConfirm={() => onSubmit(formData as W8BENEFormType)}
          onClose={() => setShowConfirmationModal(false)}
          onCancel={() => setShowConfirmationModal(false)}
          onError={() => {
            setShowConfirmationModal(false);
            snackbar.show(t(translationKeys.FORM_FEEDBACK_ERROR), {
              severity: "error",
            });
          }}
        />
      )}
    </>
  );
}

export default W8BENEForm;
