import React from "react";
import {
  WhmMenuItem,
  WhmTypography,
  WhmDialog,
  WhmDialogTitle,
  WhmDialogContent,
  WhmDialogActions,
  WhmButton,
  WhmIconButton,
  WhmBox,
  WhmLoadingButton,
} from "@securitize/reactjs-whm";
import CloseIcon from "@mui/icons-material/Close";
import type { Theme } from "@securitize/reactjs-whm";
import { DocumentsRecord } from "../../../types/documents.interface";
import {
  deleteImportedTaxForms,
  deleteTaxForm,
} from "../../../data/rest/documents";
import Translate from "../../../components/Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";

interface DeleteMenuItemProps {
  document: DocumentsRecord;
  loadServerRows: () => void;
}

export const DeleteMenuItem: React.FC<DeleteMenuItemProps> = ({
  document,
  loadServerRows,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    setLoading(true);
    const deleteAction = document.imported
      ? deleteImportedTaxForms()
      : deleteTaxForm(document.id);
    void deleteAction.then(() => {
      setDeleteModalOpen(false);
      loadServerRows();
      setLoading(false);
    });
  };

  const handleModalClose = () => {
    setDeleteModalOpen(false);
  };

  return (
    <>
      <WhmMenuItem
        onClick={handleDeleteClick}
        sx={{ color: (theme: Theme) => theme.palette.error.main }}
      >
        <i
          className="wh-icon wh-icon-delete"
          style={{ paddingRight: "8px", fontSize: "20px" }}
        />
        <WhmTypography variant="body1">
          {" "}
          <Translate translationKey={TranslationKeys.MENU_ITEM_DELETE} />
        </WhmTypography>
      </WhmMenuItem>
      <WhmDialog
        open={deleteModalOpen}
        onClose={handleModalClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <WhmBox>
          <WhmDialogTitle
            id="delete-dialog-title"
            variant="h4"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Translate translationKey={TranslationKeys.DIALOG_DELETE_TITLE} />
            <WhmIconButton
              aria-label="close"
              onClick={handleModalClose}
              size="small"
              sx={(theme: Theme) => ({
                padding: 0,
                color: theme.palette.common.black,
              })}
            >
              <CloseIcon />
            </WhmIconButton>
          </WhmDialogTitle>

          <WhmDialogContent>
            <WhmTypography id="delete-dialog-description" variant="body1">
              <Translate
                translationKey={TranslationKeys.DIALOG_DELETE_BODY_1}
              />
            </WhmTypography>
            <WhmTypography variant="body1">
              <Translate
                translationKey={TranslationKeys.DIALOG_DELETE_BODY_2}
              />
            </WhmTypography>
          </WhmDialogContent>
          <WhmDialogActions>
            <WhmButton
              color="secondary"
              onClick={handleModalClose}
              variant="outlined"
              size="medium"
            >
              <Translate
                translationKey={TranslationKeys.DIALOG_ACTION_CANCEL}
              />
            </WhmButton>
            <WhmLoadingButton
              color="error"
              onClick={handleDeleteConfirm}
              variant="contained"
              size="medium"
              loading={loading}
              loadingPosition="start"
              sx={{
                ".MuiCircularProgress-root": { display: "flex" },
              }}
            >
              <Translate
                translationKey={TranslationKeys.DIALOG_ACTION_DELETE}
              />
            </WhmLoadingButton>
          </WhmDialogActions>
        </WhmBox>
      </WhmDialog>
    </>
  );
};
