import { TranslationKey } from "../../contexts/I18nContext/translationKeys";
import useTranslations from "../../hooks/useTranslations";

export interface TranslateProps {
  translationKey: TranslationKey | string;
  replacements?: Record<string, string | number>;
}

export default function Translate({
  translationKey,
  replacements,
}: TranslateProps): JSX.Element {
  const { getTranslation } = useTranslations();

  return <>{getTranslation(translationKey, replacements)}</>;
}
