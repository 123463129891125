// eslint-disable @typescript-eslint/no-unsafe-assignment
import { useCallback, useContext } from "react";
import useUserInfo from "./useUserInfo";
import { MixPanelContext } from "../contexts/MixPanelContext";

export enum TrackingType {
  TA_PAYOUTS_OPENED_TAX_FORM_PROCESS = "ta-payouts-opened-tax-form-process",
  TA_PAYOUTS_W8_DELETED = "ta-payouts-w8-deleted",
  TA_PAYOUTS_W8BEN_E_DELETED = "ta-payouts-w8ben-e-deleted",
  TA_PAYOUTS_W9_DELETED = "ta-payouts-w9-deleted",
  // W8 events
  TA_PAYOUTS_W8_BACK_PAGE_0 = "ta-payouts-w8-back-page-0",
  TA_PAYOUTS_W8_FORWARD_PAGE_1 = "ta-payouts-w8-forward-page-1",
  TA_PAYOUTS_W8_BACK_PAGE_1 = "ta-payouts-w8-back-page-1",
  TA_PAYOUTS_W8_FORWARD_PAGE_2 = "ta-payouts-w8-forward-page-2",
  TA_PAYOUTS_W8_BACK_PAGE_2 = "ta-payouts-w8-back-page-2",
  TA_PAYOUTS_W8_FORWARD_PAGE_3 = "ta-payouts-w8-forward-page-3",
  TA_PAYOUTS_W8_SUBMIT = "ta-payouts-w8-submit",
  // W8BEN-E
  TA_PAYOUTS_W8BEN_E_BACK_PAGE_0 = "ta-payouts-w8ben-e-back-page-0",
  TA_PAYOUTS_W8BEN_E_FORWARD_PAGE_1 = "ta-payouts-w8ben-e-forward-page-1",
  TA_PAYOUTS_W8BEN_E_DOWNLOAD = "ta-payouts-w8ben-e-download",
  TA_PAYOUTS_W8BEN_E_UPLOAD = "ta-payouts-w8ben-e-uplaod",
  // W9 Events
  TA_PAYOUTS_W9_BACK_PAGE_0 = "ta-payouts-w9-back-page-0",
  TA_PAYOUTS_W9_FORWARD_PAGE_1 = "ta-payouts-w9-forward-page-1",
  TA_PAYOUTS_W9_BACK_PAGE_1 = "ta-payouts-w9-back-page-1",
  TA_PAYOUTS_W9_OPEN_PAGE_2 = "ta-payouts-w9-open-page-2",
  TA_PAYOUTS_W9_SUBMIT = "ta-payouts-w9-submit",
}

const useMixPanel = () => {
  const userInfo = useUserInfo();
  const { MixPanel } = useContext(MixPanelContext);

  return useCallback(
    (eventType: TrackingType, data?: any) => {
      const {
        verificationStatus,
        accreditationStatus,
        suitabilityStatus,
        accountStatus,
        brokerDealerId,
      } = userInfo;

      MixPanel.track(eventType, {
        ...data,
        accountStatus: accountStatus,
        accreditationStatus: accreditationStatus,
        suitabilityStatus: suitabilityStatus,
        verificationStatus: verificationStatus,
        brokerDealerId: brokerDealerId,
      });
    },
    [userInfo, MixPanel],
  );
};

export default useMixPanel;
