import React from "react";
import {
  WhmIconButton,
  WhmMenuItem,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { DocumentsRecord } from "../../../types/documents.interface";
import { getDownloadLink } from "../../../common/downloadUtils";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import Translate from "../../../components/Translation/Translate";

interface DownloadMenuItemProps {
  document: DocumentsRecord;
  isMobile?: boolean;
}

export const DownloadMenuItem: React.FC<DownloadMenuItemProps> = ({
  document,
  isMobile = false,
}) => {
  const handleDownload = async (item: DocumentsRecord) => {
    await getDownloadLink(item);
  };

  if (isMobile) {
    return (
      <WhmMenuItem onClick={() => void handleDownload(document)}>
        <i
          className={`wh-icon-download`}
          style={{ paddingRight: "8px", fontSize: "20px", color: "#6c6c6c" }}
        />
        <WhmTypography variant="body1">
          <Translate translationKey={TranslationKeys.MENU_ITEM_DOWNLOAD} />
        </WhmTypography>
      </WhmMenuItem>
    );
  }

  return (
    <WhmIconButton
      color="default"
      size="small"
      disabled={document.imported}
      onClick={() => void handleDownload(document)}
      aria-label="download"
    >
      <i className={`wh-icon-download`} />
    </WhmIconButton>
  );
};
