import React from "react";
import {
  WhmIconButton,
  WhmMenu,
  WhmDivider,
  WhmBox,
} from "@securitize/reactjs-whm";
import { DocumentsRecord } from "../../../types/documents.interface";
import { isOtherDocument, isTaxDocument } from "../../../common/taxForms.utils";
import { PostMailMenuItem } from "./PostMailMenuItem";
import { DeleteMenuItem } from "./DeleteMenuItem";
import { DownloadMenuItem } from "./DownloadMenuItem";

interface GridMenuProps {
  row: DocumentsRecord;
  loadServerRows: () => void;
  isMobile?: boolean;
}

export const GridMenu: React.FC<GridMenuProps> = ({
  row,
  loadServerRows,
  isMobile = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems: React.ReactNode[] = [];

  if (!isTaxDocument(row.documentType) && !isOtherDocument(row.documentType)) {
    menuItems.push(<PostMailMenuItem key="postmail" isMobile={isMobile} />);
  }

  if (isTaxDocument(row.documentType)) {
    menuItems.push(
      <DeleteMenuItem
        key="delete"
        document={row}
        loadServerRows={loadServerRows}
      />,
    );
  }

  if (isMobile) {
    menuItems.push(
      <DownloadMenuItem key="download" document={row} isMobile={true} />,
    );
  }

  return (
    <WhmBox
      sx={
        isMobile ? { display: "flex", justifyContent: "flex-end" } : undefined
      }
    >
      <WhmIconButton
        color={isMobile ? "inherit" : "default"}
        size="small"
        id="basic-button"
        disabled={isOtherDocument(row.documentType) && !isMobile}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <i className="wh-icon-more-options" />
      </WhmIconButton>
      <WhmMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.length > 0 &&
          menuItems.map((item, index) => (
            <React.Fragment key={index}>
              {item}
              {index < menuItems.length - 1 && <WhmDivider />}
            </React.Fragment>
          ))}
      </WhmMenu>
    </WhmBox>
  );
};
