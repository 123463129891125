import { WhmTextField, WhmTypography } from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { TwoColsInputsContainer } from "../InputContainers";
import { obfuscateString } from "../../../../common/string.utils";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";

type Props = {
  disabled?: boolean;
};

function W8BENTaxInformationInputs({ disabled }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    USTaxPayerId: string;
    foreignTaxPayerId: string;
  }>();

  return (
    <>
      <TwoColsInputsContainer direction="row" spacing={2}>
        <WhmTextField
          disabled={disabled}
          id="USTaxPayerId"
          name="USTaxPayerId"
          label={t(
            translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_US_TAX_ID,
          )}
          value={obfuscateString(formik.values.USTaxPayerId)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.USTaxPayerId && Boolean(formik.errors.USTaxPayerId)
          }
          helperText={formik.touched.USTaxPayerId && formik.errors.USTaxPayerId}
        />
        <WhmTextField
          disabled={disabled}
          id="foreignTaxPayerId"
          name="foreignTaxPayerId"
          label={t(
            translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_FOREIGN_TAX_ID,
          )}
          value={obfuscateString(formik.values.foreignTaxPayerId)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.foreignTaxPayerId &&
            Boolean(formik.errors.foreignTaxPayerId)
          }
          helperText={
            formik.touched.foreignTaxPayerId && formik.errors.foreignTaxPayerId
          }
        />
      </TwoColsInputsContainer>
      <WhmTypography sx={{ marginTop: "0.5rem" }}>
        {t(translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_HINT)}
      </WhmTypography>
    </>
  );
}

export default W8BENTaxInformationInputs;
