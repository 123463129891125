import useTranslations from "./useTranslations";
import { translationKeys } from "../contexts/I18nContext";

const itemIndexResolver: Record<"PAYEE" | "FATCA", (index: number) => string> =
  {
    PAYEE: (index: number) => `${index + 1}`,
    FATCA: (index: number) => String.fromCharCode(64 + (index + 1)),
  };

function useExemptionCodes() {
  const { getTranslation: t } = useTranslations();

  const PayeeCodesList = Array.from({ length: 13 }).map((_, index) =>
    t(
      `${t(translationKeys.FORM_SECTION_EXEMPTIONS_PAYEE_CODE)}${itemIndexResolver["PAYEE"](index)}`,
    ),
  );
  const fatcaCodesList = Array.from({ length: 13 }).map((_, index) =>
    t(
      `${t(translationKeys.FORM_SECTION_EXEMPTIONS_FATCA_CODE)}${itemIndexResolver["FATCA"](index)}`,
    ),
  );

  const codesListResolver: Record<"PAYEE" | "FATCA", string[]> = {
    PAYEE: PayeeCodesList,
    FATCA: fatcaCodesList,
  };

  return {
    PayeeCodesList,
    fatcaCodesList,
    codesListResolver,
    itemIndexResolver,
  };
}

export default useExemptionCodes;
