import React, { useState } from "react";
import {
  WhmMenuItem,
  WhmTypography,
  WhmDialog,
  WhmDialogTitle,
  WhmDialogContent,
  WhmDialogActions,
  WhmButton,
  WhmIconButton,
  WhmBox,
  WhmSnackbar,
} from "@securitize/reactjs-whm";
import CloseIcon from "@mui/icons-material/Close";
import { Mailbox } from "../../../components/icons";
import type { Theme } from "@securitize/reactjs-whm";
import Translate from "../../../components/Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import useTranslations from "../../../hooks/useTranslations";
import translationKeys from "../../../contexts/I18nContext/translationKeys";

const PostMailDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  isMobile: boolean;
}> = ({ open, onClose, isMobile }) => {
  const [copied, setCopied] = useState(false);

  const { getTranslation: t } = useTranslations();

  const email = "investorsupport@securitize.io";
  const handleCopy = () => {
    navigator.clipboard.writeText(email).then(() => setCopied(true));
  };
  const openHandler = () => setCopied(false);
  return (
    <>
      <WhmDialog
        open={open}
        onClose={onClose}
        aria-labelledby="postmail-dialog-title"
        aria-describedby="postmail-dialog-description"
        fullWidth
        {...(!isMobile && { maxWidth: "xs" })}
        fullScreen={isMobile}
      >
        <WhmBox
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <WhmDialogTitle
            id="postmail-dialog-title"
            variant="h4"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Translate translationKey={TranslationKeys.DIALOG_POSTMAIL_TITLE} />
            <WhmIconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              sx={(theme: Theme) => ({
                padding: 0,
                paddingTop: "7px",
                alignSelf: "self-start",
                "&:hover": {
                  backgroundColor: "#fff",
                },
                color: theme.palette.common.black,
              })}
            >
              <CloseIcon />
            </WhmIconButton>
          </WhmDialogTitle>

          <WhmDialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <WhmBox sx={{ display: "flex", justifyContent: "center" }}>
              <Mailbox />
            </WhmBox>
            <WhmTypography
              id="postmail-dialog-description"
              variant="body1"
              sx={{ paddingTop: "16px" }}
            >
              <Translate
                translationKey={TranslationKeys.DIALOG_POSTMAIL_BODY_1}
              />
            </WhmTypography>
            <WhmBox
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <WhmTypography
                onClick={handleCopy}
                variant="body1"
                sx={{
                  color: "primary.main",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover span": {
                    textDecoration: "underline !important",
                  },
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <span style={{ textDecoration: "none" }}>
                  <Translate
                    translationKey={TranslationKeys.DIALOG_POSTMAIL_BODY_2}
                  />
                </span>
                <i className="wh-icon-copy" style={{ marginLeft: "8px" }} />
              </WhmTypography>
            </WhmBox>
          </WhmDialogContent>

          <WhmDialogActions
            sx={{
              padding: isMobile ? "16px" : "8px",
              position: isMobile ? "sticky" : "static",
              bottom: isMobile ? 0 : "auto",
            }}
          >
            <WhmButton
              color="primary"
              onClick={onClose}
              variant="contained"
              fullWidth={isMobile}
              sx={{
                alignSelf: isMobile ? "stretch" : "flex-end",
              }}
            >
              <Translate
                translationKey={TranslationKeys.DIALOG_POSTMAIL_ACTION}
              />
            </WhmButton>
          </WhmDialogActions>
        </WhmBox>
        <WhmSnackbar
          message={t(translationKeys.DIALOG_POSTMAIL_SNACKBAR)}
          open={copied}
          onClose={openHandler}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: isMobile ? "top" : "bottom",
            horizontal: "center",
          }}
        />
      </WhmDialog>
    </>
  );
};

export const PostMailMenuItem = ({ isMobile }: { isMobile: boolean }) => {
  const [postMailModalOpen, setPostMailModalOpen] = React.useState(false);

  const handlePostMailClick = () => {
    setPostMailModalOpen(true);
  };

  const handleModalClose = () => {
    setPostMailModalOpen(false);
  };

  return (
    <>
      <WhmMenuItem onClick={handlePostMailClick}>
        <i
          className="wh-icon wh-icon-postmail"
          style={{ paddingRight: "8px", fontSize: "20px", color: "#6c6c6c" }}
        />
        <WhmTypography variant="body1">
          <Translate translationKey={TranslationKeys.MENU_ITEM_POSTMAIL} />
        </WhmTypography>
      </WhmMenuItem>
      <PostMailDialog
        open={postMailModalOpen}
        onClose={handleModalClose}
        isMobile={isMobile}
      />
    </>
  );
};
